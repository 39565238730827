@font-face {
  font-family: "GothamMedium";
  src: url(./assets/Fonts/GothamMedium.ttf);
}
@font-face {
  font-family: "GothamBook";
  src: url(./assets/Fonts/GothamBook.ttf);
}
@font-face {
  font-family: "GothamLight";
  src: url(./assets/Fonts/GothamLight.ttf);
}
@font-face {
  font-family: "GothamBold";
  src: url(./assets/Fonts/GothamBold.ttf);
}

body {
  font-family: "GothamMedium";
}
