.aboutUsContainer{
  background-image: url(../../assets/Images/roadmapbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.vertical-timeline::before {
  width: 2px;
}
.vertical-timeline-element-icon {
    box-shadow: none;
    box-shadow: 0px 0px 25px rgba(86, 184, 255, 0.5);
  }