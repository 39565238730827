@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .customContainer {
    @apply w-95 md:w-90 lg:w-80 m-auto
  }
  .primaryActionBtn {
    @apply mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-primary rounded-md shadow-sm text-base font-medium text-white hover:text-primary bg-primary hover:bg-transparent focus:outline-none duration-500 sm:w-auto
  }
  .secondaryActionBtn {
    @apply mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-secondary rounded-md shadow-sm text-base font-medium text-white hover:text-secondary bg-secondary hover:bg-transparent focus:outline-none duration-500 sm:w-auto
  }
  .tertiaryActionBtn {
    @apply mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-tertiary rounded-md shadow-sm text-base font-medium text-white hover:text-secondary bg-secondary hover:bg-transparent focus:outline-none duration-500 sm:w-auto
  }
}
.linearBg {
  background-image: linear-gradient( 276deg , #00ddf4 0%, #2dab66 100%);
}

.clipPathBg {
  background-image: linear-gradient( 276deg , #00ddf4 0%, #2dab66 100%);
  clip-path: polygon(0 15vh,100% 0,100% 100%,0 100%);
}

.gothamMedium {
  font-family: 'GothamMedium';
}